<template>
  <div id="space">
    <div id="cont"></div>
    <div class="infoBox">
      {{ nowClickBuilding.name }}
    </div>
  </div>
</template>
<script>
import * as THREE from "three";
import * as GEOLIB from "geolib";
import { MapControls } from "three/examples/jsm/controls/OrbitControls";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import axios from "axios";
import Stats from "three/examples/jsm/libs/stats.module";
// import shangcheng from "../../assets/shangcheng";
export default {
  name: "space",
  data() {
    return {
      nowClickBuilding: {},
    };
  },
  mounted() {
    // 初始化数据
    // THREE Space
    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.controls = null;
    this.raycaster = null;

    //地图中心
    this.center = [120.1997388, 30.1979898];

    // 路线跑动
    this.FLAG_ROAD_ANI = true;

    // debug
    this.stats = null;

    // 材料
    this.MAT_BUILDING = null;
    this.MAT_ROAD = null;

    // 动画线条
    this.Animated_Line_Distance = [];
    this.iR = null;
    this.scene_line = null;

    // Helper
    this.collder_building = [];

    // 连接地图模型
    this.geos_building = [];

    // 相机 位置
    this.cameraX = 18;
    this.cameraZ = -5;
    this.cameraDone = false;

    // 点击建筑的信息
    this.nowClickBuilding = {};

    // 绘制建筑之间的曲线  点集合
    this.linePoints = [];

    this.Await();
    // On user resize window
    let that = this;
    window.addEventListener("resize", onWindowResize, false);
    function onWindowResize() {
      that.camera.aspect = window.innerWidth / window.innerHeight;
      that.camera.updateProjectionMatrix();
      that.renderer.setSize(window.innerWidth, window.innerHeight);
    }
    onWindowResize();

    document.getElementById("cont").addEventListener("click", (event) => {
      let mouse = {
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1,
      };

      let hitted = that.Fire(mouse);
      this.nowClickBuilding = hitted;
      console.log(this.camera.position);
    });
  },
  methods: {
    cameraMove() {
      requestAnimationFrame(this.cameraMove);
      if (this.cameraX >= -28 && !this.cameraDone) {
        this.cameraX -= 0.05;
      } else {
        this.cameraDone = true;
        return false;
      }
      // console.log(this.cameraX);
      this.camera.position.set(this.cameraX, 14, this.cameraZ); // x, y, z
    },
    Await() {
      let cont = document.getElementById("cont");

      // Init Scene  加载显示区域
      this.scene = new THREE.Scene();

      // 添加静态路线
      this.scene_line = new THREE.Group();
      this.scene.add(this.scene_line);
      // this.scene.background = new THREE.Color(0x2222);

      // Init Camera  加载摄像机
      this.camera = new THREE.PerspectiveCamera(
        25,
        window.innerWidth / window.innerHeight,
        1,
        1000
      );
      this.camera.position.set(
        45,
        18,
        20
      ); // x, y, z

      // 相机动画
      // requestAnimationFrame(this.cameraMove);

      // Init raycaster

      this.raycaster = new THREE.Raycaster();

      // Init iR   合并的建筑
      this.iR = new THREE.Group();
      this.iR.name = "Interactive Root";
      this.scene.add(this.iR);

      // Init Light  光线
      let light0 = new THREE.AmbientLight(0xfafafa, 0.25);

      let light1 = new THREE.PointLight(0xfafafa, 0.4);
      light1.position.set(200, 90, 40);

      let light2 = new THREE.PointLight(0xfafafa, 0.4);
      light2.position.set(200, 90, -40);

      this.scene.add(light0);
      this.scene.add(light1);
      this.scene.add(light2);

      // 添加网格
      // let gh = new THREE.GridHelper(
      //   60,
      //   200,
      //   new THREE.Color(0x555555),
      //   new THREE.Color(0x333333)
      // );
      // this.scene.add(gh);

      //添加地面
      var planeGeo = new THREE.PlaneGeometry(100, 100);
      var planeMat = new THREE.MeshPhongMaterial({ color: "#000000" });
      var plane = new THREE.Mesh(planeGeo, planeMat);
      plane.receiveShadow = true;
      plane.position.y = -0.01;
      plane.rotation.x = -0.5 * Math.PI;
      this.scene.add(plane);

      // 添加方块
      // let that = this;
      // var loader = new THREE.TextureLoader();
      // var texture = loader.load('https://suezp.cn/server//building/building_1.png', function () { 
      //   that.renderer.render(that.scene, that.camera);
      // });
      // var material1 = new THREE.MeshLambertMaterial({
      //   map: texture,
      // });
      // const geometry1 = new THREE.BoxGeometry(0.5, 4.5, 3);
      // // const material1 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
      // const cube = new THREE.Mesh(geometry1, material1);
      // cube.position.y = 5;
      // this.scene.add(cube);

      // 添加三维曲线
      var geometry = new THREE.BufferGeometry();
      var curve = new THREE.CubicBezierCurve3(
        new THREE.Vector3(1.20199909, 0, 0.302028702),
        new THREE.Vector3(1.20199909, 5, 0.302028702),
        new THREE.Vector3(-1.20199909, 5, -0.302028702),
        new THREE.Vector3(1.502265058, 0, -0.501930159)
      );
      var points = curve.getPoints(1000);
      geometry.setFromPoints(points);
      var line = new THREE.Line(
        geometry,
        new THREE.LineBasicMaterial({
          // 线的颜色
          color: "0x00ffff",
          transparent: true,
          opacity: 0.8,
          depthFunc: THREE.AlwaysDepth,
        })
      );
      line.material.dashSize = 0;
      line.material.gapSize = 1000;
      line.position.y = 0;
      line.material.transparent = true;
      line.material.opacity = 1;
      this.scene_line.add(line);

      // Init renderer  初始化渲染
      this.renderer = new THREE.WebGLRenderer({ antialias: true }); // 默认开启
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);

      cont.appendChild(this.renderer.domElement);

      //  Init controls  控制器
      this.controls = new MapControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.25;
      this.controls.screenSpacePanning = false;
      this.controls.maxDistance = 800;

      this.controls.update();

      // 显示帧数
      this.stats = new Stats();
      cont.appendChild(this.stats.domElement);

      this.Update();

      // 建筑材质
      this.MAT_BUILDING = new THREE.MeshPhongMaterial({
        color: "#2e62cd",
        transparent: true,
        opacity: 0.8,
      });

      // var loader = new THREE.TextureLoader();
      // var texture = loader.load('https://suezp.cn/server//building/building_2.png', function () {});
      // this.MAT_BUILDING = new THREE.MeshLambertMaterial({
      //   map: texture,
      //   transparent: true,
      //   color: "##80c6e8",
      //   opacity: 0.75
      // });


      // 道路材质
      this.MAT_ROAD = new THREE.LineBasicMaterial({ color: 0x2f9bff });

      this.GetGeoJson();
    },
    Update() {
      requestAnimationFrame(this.Update);

      this.renderer.render(this.scene, this.camera);
      this.controls.update();
      this.updateAnimatedLine();
      this.stats.update();
    },
    async GetGeoJson() {
      await axios.get("https://suezp.cn/server//demo1.geojson").then((res) => {
        this.loadBuilding(res.data);
      });
      // await this.loadBuilding(binjiang);

      let elp1 = this.linePoints[100];
      let elp2 = this.linePoints[203];

      // console.log(elp1,elp2)

      var geometry = new THREE.BufferGeometry();
      var curve = new THREE.CubicBezierCurve3(
        new THREE.Vector3(elp1[0], 0, elp1[1]),
        new THREE.Vector3(elp1[0], 5, elp1[1]),
        new THREE.Vector3(elp2[0], 5, elp2[1]),
        new THREE.Vector3(elp2[0], 0, elp2[1])
      );
      var points1 = curve.getPoints(1000);
      geometry.setFromPoints(points1);
      var line = new THREE.Line(
        geometry,
        new THREE.LineDashedMaterial({ color: 0x00ffff })
      );
      line.material.dashSize = 0;
      line.material.gapSize = 1000;
      line.position.y = 0;
      line.material.transparent = true;
      line.material.opacity = 1;
      this.scene_line.add(line);
    },
    loadBuilding(data) {
      let fel = data.features;
      fel.forEach((ele) => {
        if (!ele["properties"]) return;
        if (ele.properties["building"]) {
          //建筑
          this.addBuilding(
            ele.geometry.coordinates,
            ele.properties,
            ele.properties["building:levels"]
              ? ele.properties["building:levels"]
              : ele.properties["height"]
          );
        } else if (ele.properties["highway"]) {
          // 道路
          if (
            ele.geometry.type == "LineString" &&
            ele.properties["highway"] != "pedestrain" &&
            ele.properties["highway"] != "footway" &&
            ele.properties["highway"] != "path"
          ) {
            this.addRoad(ele.geometry.coordinates);
          }
        }
      });
      let that = this;
      // 连接 所有模型
      this.$nextTick(() => {
        let mergeGeometry = BufferGeometryUtils.mergeBufferGeometries(
          that.geos_building
        );
        let mesh = new THREE.Mesh(mergeGeometry, this.MAT_BUILDING);
        that.iR.add(mesh);
      });
    },
    addRoad(data) {
      let points = [];
      data.forEach((ele) => {
        if (!data[0][1]) return;

        if (!ele[0] || !ele[1]) return;

        let elp = [ele[0], ele[1]];

        elp = this.GPSRelativePosition(elp, this.center);

        points.push(new THREE.Vector3(elp[0], 0.5, elp[1]));

        this.linePoints.push(elp);

        let geometry = new THREE.BufferGeometry().setFromPoints(points);
        geometry.rotateZ(Math.PI);

        let line = new THREE.Line(geometry, this.MAT_ROAD);
        line.computeLineDistances();
        line.position.y = 0.5;

        // 添加交通路线 动画
        if (this.FLAG_ROAD_ANI) {
          let lineLength =
            geometry.attributes.lineDistance.array[
              geometry.attributes.lineDistance.count - 1
            ];
          if (lineLength > 5) {
            let anLine = this.addAnimatedLine(geometry, lineLength);
            this.scene_line.add(anLine);
          }
        }
      });
    },
    // 动画路线
    addAnimatedLine(geometry, length) {
      let animatedLine = new THREE.Line(
        geometry,
        new THREE.LineDashedMaterial({ color: 0x00ffff })
      );
      animatedLine.material.dashSize = 10;
      animatedLine.material.gapSize = 10;
      animatedLine.position.y = 0.5;
      animatedLine.material.transparent = true;
      this.Animated_Line_Distance.push(length);

      return animatedLine;
    },
    updateAnimatedLine() {
      if (this.scene_line.children.length <= 0) return;

      for (let i = 0; i < this.scene_line.children.length; i++) {
        let line = this.scene_line.children[i];

        let dash = parseInt(line.material.dashSize);
        let length = parseInt(this.Animated_Line_Distance[i]);

        if (dash > length) {
          // recover
          line.material.dashSize = 0;
          line.material.opacity = 1;
        } else {
          line.material.dashSize += 0.05;
          line.material.opacity =
            line.material.opacity > 0 ? line.material.opacity - 0.004 : 0;
        }
      }
    },
    addBuilding(data, info, height = 1) {
      let shape;
      let holes = []; //建筑中间的镂空
      if (!data) return;

      // 配置shape
      for (let i = 0; i < data.length; i++) {
        let ele = data[i];

        if (i == 0) {
          shape = this.getShape(ele, this.center);
        } else {
          holes.push(this.getShape(ele, this.center));
        }
      }
      // 添加细节
      for (let i = 0; i < holes.length; i++) {
        shape.holes.push(holes[i]);
      }

      let config = {
        curveSegment: 1,
        depth: 0.015 * (height ? height : 1),
        bevelEnabled: false,
      };
      let geometry = this.getGeometry(shape, config);
      geometry.rotateX(Math.PI / 2);
      geometry.rotateZ(Math.PI);

      // 合并每个建筑
      this.geos_building.push(geometry);

      // 每个建筑 生成 一个模型
      // let mesh = new THREE.Mesh(geometry, this.MAT_BUILDING);
      // this.scene.add(mesh);

      //建筑选择器
      let helper = this.getHelper(geometry);

      if (helper) {
        helper.name = info["name"] ? info["name"] : "Bulding";
        helper.info = info;
        this.collder_building.push(helper);
      }
    },
    // 选择模型助手
    getHelper(geometry) {
      if (!geometry.boundingBox) {
        geometry.computeBoundingBox();
      }

      let box3 = geometry.boundingBox;

      if (!isFinite(box3.max.x)) {
        return false;
      }

      let helper = new THREE.Box3Helper(box3, 0xffff00);
      helper.updateMatrixWorld();

      return helper;
    },
    // rayCaster
    Fire(mouse) {
      this.raycaster.setFromCamera(mouse, this.camera);

      let intersects = this.raycaster.intersectObjects(
        this.collder_building,
        true
      );
      if (intersects.length > 0) {
        return intersects[0].object;
      } else {
        return false;
      }
    },
    getShape(points, center) {
      let shape = new THREE.Shape();
      if (!points.forEach) {
        shape.moveTo(points, points + 0.2);
        return shape;
      }
      points.forEach((point, i) => {
        let elp = this.GPSRelativePosition(point, center);
        if (i == 0) {
          //第一个
          shape.moveTo(elp[0], elp[1]);
        } else {
          shape.lineTo(elp[0], elp[1]);
        }
      });
      return shape;
    },

    getGeometry(shape, config) {
      let geometry = new THREE.ExtrudeGeometry(shape, config);
      geometry.computeBoundingBox();

      return geometry;
    },

    // 因为有地球有弧度 来精确直线位置
    GPSRelativePosition(objPosi, centerPosi) {
      // Get GPS distance
      let dis = GEOLIB.getDistance(objPosi, centerPosi);

      //get Bearing angle
      let bearing = GEOLIB.getRhumbLineBearing(objPosi, centerPosi);

      // 计算距离 x
      let x = centerPosi[0] + dis * Math.cos((bearing * Math.PI) / 180);

      // 计算距离 y
      let y = centerPosi[1] + dis * Math.sin((bearing * Math.PI) / 180);

      return [-x / 100, y / 100];
    },
  },
};
</script>

<style scoped lang="less">
#space {
  position: relative;
  height: 100%;
  width: 100%;
}
.infoBox {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 100px;
  height: 80px;
  background: #fff;
  padding: 5px;
}
</style>
